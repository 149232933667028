import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    name: {
      type: [String],
      default: 'Untitled'
    }
  },
  computed: {
    ...mapGetters({
      paperBuilderId: 'App/paperBuilderId',
      paperBuilderDrop: 'App/paperBuilderDrop'
    })
  },
  methods: {
    ...mapActions({
      setPaperBuilderDrop: 'App/setPaperBuilderDrop',
      addPaperBuilderCanvas: 'PaperBuilder/addCanvas'
    }),
    addItemDrop () {
      if (this.paperBuilderDrop) {
        this.addItem()
        this.setPaperBuilderDrop(null)
      }
    },
    addItem () {
      const { left, top } = this.paperBuilderDrop || { left: '20px', top: '20px' }
      console.log(`left: ${left}`, `top: ${top}`)
    }
  }
}
